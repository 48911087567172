/* Navbar animation */

.animation1 {
  width: 99%;
}

.animation2 {
  width: 86%;
}

/* Navbar section */
.container {
  width: 50px;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.button {
  width: 40px;
  height: 40px;
  background-color: #4782da;
  color: white;
  cursor: pointer;
  /* border-radius: 5px 0 0 5px; */
  border-radius: 50%;
  margin-bottom: 10px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navlist {
  visibility: hidden;
  opacity: 0;
  /* margin-top: 10px; */
  border-radius: 5px;
  transform: translateX(100%);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out,
    visibility 0.3s ease-out;
}

.navlistshow {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.3s ease-in, opacity 0.3s ease-in,
    visibility 0.3s ease-in;
}

/* .navbarPlace {
  width: 4%;
  position: fixed;
  right: 0px;
  z-index: 1111;
  top: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 1s;
}
.navbarPlace:hover {
  width: 12%;
} */

/* .hover {
  width: 12%;
  position: fixed;
  right: 0px;
  z-index: 1111;
  top: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 1s;
} */

.navbarProfile {
  visibility: visible;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  color: #cccccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Navbar CSS */

.navbarMain {
  box-sizing: border-box;
  display: flex;
  flex-direction: column !important;
  /* width: 155px; */
  row-gap: 3px;
}

.navItem {
  box-sizing: border-box;
  display: flex;
  background-color: #233044;
  height: 40px;
  border-bottom-left-radius: 25%;
  border-top-left-radius: 25%;
}

.navbarMain :hover {
  box-sizing: border-box;
  background-color: #304668;
  cursor: pointer;
}

.icon {
  box-sizing: border-box;
  border: 1px solid #ffffff;
  border-radius: 50%;
  font-size: 40px !important;
  margin-right: 10px;
  margin-left: -4px;
}

.navItemText {
  box-sizing: border-box;
  height: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
}

/* MyProfile Page CSS */

.infoSection {
  transition: all 1s ease;
  animation-fill-mode: forwards;
}

.myCabinetFirstSection {
  background-color: #233044;
  margin-top: 25px;
  padding: 10px;
  border-radius: 1.5%;
}

.inputs {
  box-sizing: border-box;
  width: 49%;
}

.imageSection {
  box-sizing: border-box;
  width: 30%;
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.myCabinetSecondSection {
  margin-top: 50px;
}
.myCabinetThirdSection {
  margin-top: 30px;
}

/* My Wages Page */
.wagesDateSection {
  margin-top: 5vh;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20vh;
}

.wagesDatePicker {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.wagesTableSection {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wagesTableBox {
  box-sizing: border-box;
  width: 90%;
  border: 2px solid #8c95a3;
}

.wagesTableFirstRow {
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  display: flex;
  border: 1px solid #8c95a3;
}

.wagesIconsBox {
  width: 25%;
  height: 100%;
  border: 1px solid #8c95a3;
}
.wagesTableHeaderBox {
  width: 70%;
  height: 100%;
  color: #8c95a3;
  font-size: 24px;
  line-height: 20px;
  font-weight: 800;
  display: flex;
  align-items: center;
  padding-left: 6%;
}

.wagesTableFilesBox {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 3px;
}

.wagesTableSecondRow {
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  display: flex;
}

.wagesTableSecondRowCells {
  height: 100%;
  border: 1px solid #8c95a3;
  font-size: 20px;
  color: #8c95a3;
  line-height: 20px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wagesTableThirdRow {
  display: flex;
  box-sizing: border-box;
  width: 100%;
}

.wagesTable {
  box-sizing: border-box;
  width: 100%;
}

.wagestd {
  box-sizing: border-box;
  border: 1px solid #8c95a3;
  text-align: center;
  color: #8c95a3;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.wagesTableForthRow {
  width: 100%;
  height: 50px;
}

.wagesTableFifthRow {
  display: flex;
  height: 50px;
  width: 100%;
  color: #76b480;
  font-size: 24px;
  line-height: 20px;
  font-weight: 800;
  font-style: italic;
  text-align: center;
}

/* Vacations */
.vacationsFirstSection {
  width: 100%;
  margin-top: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
}

.vacationSecondSection {
  margin-top: 30px;
  width: 100%;
}

/* Inventories */
.inventoriesFirstSection {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.inventoriesSecondSection {
  width: 100%;
}
