
.mainBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: scroll;
    border: 2px solid #000;
    min-height: 500px;
    max-height: 550px;
    min-width: 300px;
    width: 800px;
    background-color: #233044;
    padding: 16px;
}

.childBox{
    min-width: 120px;
    height: 100%;
}
.GraphicModalBtn{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.graphicForm{
    display: flex;
    gap: 20px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    min-height: 400px;
}
.graphicFormLeft{
    width: 30% !important;
    display: flex !important;
    flex-direction: column-reverse !important;
}
.graphicFormBtn{
    display: flex;
    justify-content: flex-end;
}
.graphicFormBtn button{
    margin-top: 25px;
}

.graphicFormLeftSelect{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.graphicFormLeftSelect label{
    width: 100%;
}

.leftSelect{
    width: 100%;
}

.graphicFormRight{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.graphicFormRightMain{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
}

.inptGroup{
    width: 100%;
    display: flex; 
    gap: 20px;
}
.topInputGroup{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.inpt{
    width: 49%;
}
.hiddenBtn{
    visibility: hidden;
}
.graphicFormTop{
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: flex-start;
}

.graphicFormBottom{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.addBtn{
    width: 150px;
    margin-right: 112px !important;
}

.CheckboxBottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deleteBtn{
    cursor: pointer;
}

.edit{
    width: 70%;
    display: flex;
    flex-direction: column;
}
