.custom_button {
    padding: 5px;
    /* height: 38px; */
    border: 0;
    border-radius: 5px;
    color: #fff;
    transition: .3s all ease-in-out;
    background-color: #3d008b;
    width: 100%;
}
.custom_button:hover {
    background-color: #6313ca;
}
.card_item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* transform: translateY(2.2px); */
    background-color: #2a38893b;
    transition: .3s all ease-in-out;
}