.graphicForm {
  display: flex;
  gap: 20px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
}
.graphicFormLeft {
  width: 30% !important;
  display: flex !important;
  flex-direction: column-reverse !important;
  margin-top: 20px !important;
}
.graphicFormBtn {
  display: flex;
  justify-content: flex-end;
}
.graphicFormBtn button {
  margin-top: 25px;
}

.graphicFormLeftSelect {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.graphicFormLeftSelect label {
  width: 100%;
}

.leftSelect {
  width: 100%;
}

.graphicFormRight {
  width: 70%;
  height: 400px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.graphicFormRightMain {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
}

.inptGroup {
  width: 100%;
  display: flex;
  gap: 20px;
}
.topInputGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.inpt {
  width: 49%;
}
.hiddenBtn {
  visibility: hidden;
}
.graphicFormTop {
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: flex-start;
}

.graphicFormBottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.addBtn {
  width: 150px;
  margin-right: 112px !important;
}

.CheckboxBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deleteBtn {
  cursor: pointer;
}
