/* Reset Properties --- START */
* {
  box-sizing: border-box;
  list-style: none;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}
a {
  color: #fff !important;
  text-decoration: none !important;
}
a:hover {
  color: #fff !important;
}
/* Reset Properties ----- END */
/* General CSS --- START */
.text-333 {
  color: #333 !important;
}

/* General CSS --- END */
.accordion_details {
  background-color: #1b2635;
  padding: 2px 3px 4px !important;
}

.accordion_details > div {
  border-radius: 8px !important;
  margin: 15px 10px !important;
}

.dialog > div:nth-child(n-1) > div {
  min-width: 85% !important;
  height: calc(100vh - 220px) !important;
}

.vacation_dialog > div:nth-child(n-1) > div {
  min-width: 850px !important;
}

input[type="time"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type="time"]:focus::-webkit-datetime-edit {
  color: #ffffff !important;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  width: 20px;
  height: 20px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.position__detail__table {
  border-collapse: separate;
  border-spacing: 50px 0;
}

.position__detail__table tr {
  border-bottom: 1px solid #1b2635;
}

.position__detail__table th,
td {
  padding: 10px 0px;
}

.error svg {
  fill: red;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jce {
  justify-content: flex-end;
}

.jcsb {
  justify-content: space-between;
}

.date_picker_by_n {
  height: 38px;
  border-radius: 5px;
  background: #25324e;
}

.date_picker_by_n > div {
  height: 38px;
}

.hour-weekdays div:nth-child(3) {
  background-color: #5973d129;
}

.react_select_disable div:nth-child(3) {
  background-color: #959595db;
}

.react_select_disable div:nth-child(3) > div > div {
  color: #acacac !important;
}

.hour-weekdays div:nth-child(3) > div > div {
  color: white;
}

::-webkit-scrollbar {
  width: 6px;
  height: 9px;
  /* Width of the scrollbar */
  background-color: #c2c2c200;
}

/* Customize the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
  border-radius: 6px;
  /* Rounded corners of the scrollbar thumb */
}

/* Customize the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color of the scrollbar thumb on hover */
}

/* Customize the scrollbar track on hover */
::-webkit-scrollbar-track:hover {
  background-color: #ddd;
  /* Background color of the scrollbar track on hover */
}

/* Customize the scrollbar thumb when it's in an active state (e.g., clicked) */
::-webkit-scrollbar-thumb:active {
  background-color: #333;
  /* Color of the scrollbar thumb when active */
}

/* Customize the scrollbar corner (if visible) */
::-webkit-scrollbar-corner {
  background-color: transparent;
  /* Background color of the scrollbar corner */
}

.command_dialog .css-2y9tha-MuiPaper-root-MuiDialog-paper {
  width: 100%;
  max-width: 80%;
}

.command_dialog.pending .css-2y9tha-MuiPaper-root-MuiDialog-paper {
  background-color: #18375b;
}

.command_dialog.approved .css-2y9tha-MuiPaper-root-MuiDialog-paper {
  background-color: rgba(141, 181, 160, 0.95);
}

.command_dialog.rejected .css-2y9tha-MuiPaper-root-MuiDialog-paper {
  background-color: #d32f2f;
}

.command-left {
  display: flex;
  width: 50%;
  height: 70vh;
  overflow-y: scroll;
}

.command-left > div {
  width: 95%;
}

.label_text {
  font-size: 20px;
}

.command-right {
  width: 50%;
}

.tcell {
  color: white !important;
}

.css-e9di18-MuiPaper-root-MuiAccordion-root:before {
  position: unset !important;
}
.text-right {
  text-align: right;
}
#menu-militaryService.articleNumber {
  height: 200px !important;
  width: 500px !important;
}

.error_work {
  color: #f44336;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.66;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.disable_worker {
  font-size: 10px !important;
  margin-left: 8px !important;
}
.showLi {
  display: block;
}
.hideLi {
  display: none;
}
.graphicName {
  width: 100% !important;
}
.GraphicModalBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  padding: 0px 15px;
  height: 100%;
  background: #2c5595 !important;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(250, 52, 52, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1;
  color: #fff;
  border-radius: 5px;
  font-weight: 400;
  text-decoration: none;
  transition: 0.3s;
  overflow: hidden;
  font-size: 13px;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.GraphicModalBtn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.149), transparent);
  transform: skewX(45deg) translateX(-150%);
  transition: 0.5s;
  transition-delay: 0.5s;
}
.GraphicModalBtn:hover::before {
  transform: skewX(45deg) translateX(200%);
  transition-delay: 0s;
}
.swal2-container {
  z-index: 11111;
}
.disableText {
  width: 100%;
  height: 50px;
  text-align: center;
  color: #f44336;
}
.structureHead {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.structureHead figure {
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.structureHead figure img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-align: center;
  line-height: 105px;
  text-wrap: wrap;
}
.structureHead-left {
  display: flex;
  align-self: flex-start;
  height: 100%;
}

.staffHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.staffHead figure {
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.staffHead figure img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-align: center;
  line-height: 75px;
}
.staffBtn {
  height: 100%;
  align-self: flex-start;
}

.dateBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.dateBox span {
  font-size: 12px;
  color: #f44336;
  display: inline-block;
  width: 200px;
  white-space: pre-wrap;
}
.no-data-layout {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 111;
  text-align: center;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #1f0e0ebf;
}
#notistack-snackbar {
  position: relative;
  z-index: 99999999 !important;
}
.ProfileImageEdit {
  margin: 0;
  height: 100%;
}
.ProfileImageEdit img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top center;
}
.backArrowOtp {
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

.fileInpt {
  position: relative;
  /* display: flex; */
}
.fileInptTop {
  position: relative;
}
.fileInpt button {
  position: absolute;
  left: 0;
}
.fileInpt p {
  position: absolute;
}
.date-emas > div {
  height: 42px;
}
.auto-position input {
  height: 10px;
}
#fieldOfActivitys:has(> .MuiMenu-list) {
  height: 200px !important;
}
.custom-menu {
  max-height: 100px !important; /* Set the maximum height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
  padding: 0; /* Optional: Remove padding */
  margin: 0; /* Optional: Remove margin */
}

.vacation_tracking_main,
.business_trip_main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.vacation_tracking {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vacation_tracking_day {
  display: flex;
  justify-content: center;
  align-items: center;
}
.subHeaderVacation {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vacationDivide {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.05);
}
.trans {
  color: transparent;
  background: transparent;
}

.table-c {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  gap: 10px; /* Space between cells */
  border: 1px solid #ccc; /* Border around table */
}

.table-row-c {
  display: contents; /* So all cells behave like part of the grid */
}

.table-cell-c {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
  background-color: #f9f9f9;
}

.header-c .table-cell-c {
  background-color: #f1f1f1;
  font-weight: bold;
}
.graphics #demo-simple-select {
  width: 318px;
}
.specialText {
  text-align: center;
  /* width: 200px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-picker-input input,
.ant-picker-input input::placeholder,
.ant-picker-suffix svg {
  color: #fff !important;
}
.link-like {
  background: #1b263b;
  width: 48px;
  height: 48px;
}
.link-like:hover {
  opacity: 0.5;
}
.css-1lozcra-MuiTableCell-root {
  padding: 7px !important;
}
.MuiTooltip-tooltip {
  font-size: 16px !important;
}
/* .css-2y9tha-MuiPaper-root-MuiDialog-paper {
  max-width: 100% !important;
  width: 50%;
  border-radius: 15px !important;
  /* height: 100%; */
/* } */

.edit-personal-information-img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 20px 0; */
}

.edit-personal-information-img img {
  object-fit: contain;
  object-position: top center;
}
.sticky-th {
  user-select: none !important;
  resize: none !important;
  max-width: 18px !important;
  width: 18px !important;
  position: sticky !important;
  left: -1px !important;
  z-index: 3 !important;
  background: rgba(21, 27, 76, 1) !important;
}
.sticky-td {
  position: sticky !important;
  left: -1px !important ;
  z-index: 2 !important;
  background: #1b2635 !important;
}
.sticky-th::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.025);
  z-index: 4;
}
.sticky-td::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.025);
  z-index: 3;
}

.custom-datepicker-popup {
  z-index: 299 !important;
}
