@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Inter:wght@400;700&display=swap");
.cellStyle {
  width: 186px;
  height: 100px;
  border: 1px solid #787878;
  text-align: left !important;
  padding: 7px !important;
  vertical-align: top !important;
  transition: .5s;
}
.cellStyle:hover{
  cursor: pointer;
}
.cellStyleText {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 14.4px;
}
.titleYear {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  text-align: center !important;
  font-family: "Inter", sans-serif !important;
  margin: 22px 0 10px 0 !important;
}
.toggleText {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: #e5e7eb;
}
.authorizedPerson {
  background-color: #316eb6;
  outline: none;
  border: none;
  height: 40px;
  color: #e5e7eb;
  border-radius: 2px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
}
.pie {
  width: 221px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3.72px;
}
.pieTitle {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #fcfcfc;
  margin: 15px 0 0 15px !important;
}

.pieSelect {
  margin: 19px 0 21px 15px !important;
  display: flex;
  gap: 8px;
}

.pieSelectChild {
  width: 30%;
  height: 25px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 1.86px;
  text-align: center;
}

.pieSelectText{
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
}

.pieFooterWrapper{
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin: 30px 0 23px 19px !important;
}

.pieFooterWrapperInfo{
  display: flex;
  align-items: center;
  gap: 23px;
}

.pieFooterWrapperInfoText{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #E5E7EB;
  display: flex;
  align-items: center;
  gap: 4px;
}
