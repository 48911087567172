.department {
    width: 35%;
    /* justify-content: space-between; */
}
.depart-change {
    width: 56% !important;
}
.card_title {
    font-size: 20px;
    /* word-break: break-all; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 300px;
}